import React from 'react'
import 'react-multi-carousel/lib/styles.css'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import { Image } from './Images'
import { useIntl } from 'gatsby-plugin-intl'
import { graphql, useStaticQuery } from 'gatsby'

const ImgMainDiv = styled.div`
  font-family: 'Gotham Bold', serif;
  position: relative;
  .overlay {
    cursor: pointer;
    position: absolute;
    opacity: 0.18;
    background-color: #000;
    transition: opacity 0.5s;
  }
  .img-txt {
    cursor: pointer;
    position: absolute;
    bottom: 1.2rem;
    text-align: left;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
    letter-spacing: 0.04em;
    line-height: 1.1;
    width: 90%;
    z-index: 100;
  }
  &:hover > .overlay {
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    background-color: #000;
    color: #fff;
    opacity: 0.2;
    z-index: 1;
    display: block;
  }
  .browse-txt {
    font-family: 'Gotham Book', serif;
    text-align: left;
    cursor: default;
    position: absolute;
    top: 1.2rem;
    display: none;
    font-size: 0.8rem;
    font-weight: default;
    z-index: 100;
    color: transparent;
    margin-left: 0.8rem;
    @keyframes fadeInBrowse {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    @-moz-keyframes fadeInBrowse {
      0% {
        opacity: 0;
      100% {
        opacity: 1;
      }
    }
    @-webkit-keyframes fadeInBrowse {
      0% {
        opacity: 0;
      100% {
        opacity: 1;
      }
    }
    @-o-keyframes fadeInBrowse {
      0% {
        opacity: 0;
      100% {
        opacity: 1;
      }
    }
    @-ms-keyframes fadeInBrowse {
      0% {
        opacity: 0;
      100% {
        opacity: 1;
      }
    }
    @media (min-width: 960px) {
      animation: fadeInBrowse linear 0.6s;
      -webkit-animation: fadeInBrowse linear 0.6s;
      -moz-animation: fadeInBrowse linear 0.6s;
      -o-animation: fadeInBrowse linear 0.6s;
      -ms-animation: fadeInBrowse linear 0.6s;
    }
  }
  @media (max-width: 960px) and (min-width: 813px) {
    .img-txt {
      font-size: 1rem;
    }
    .browse-txt {
      display: block;
      font-size: 0.8rem;
      z-index: 100;
    }
  }

  @media (max-width: 812px) {
    .img-txt {
      font-size: 0.9rem;
    }
    .browse-txt {
      font-size: 0.7rem;
      z-index: 100;
      margin-left: 0.5rem;
    }
  }
  &:hover {
    .browse-txt {
      display: block;
      color: #fff;
    }
    .img-txt {
    }
  }
`

const Details = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  font-family: 'Gotham Book', serif;
  color: #fff;
  text-align: left;
  margin-left: 0.8rem;
  @media (max-width: 812px) {
    margin-left: 0.5rem;
  }
`

const IconSpan = styled.span`
  margin-right: 0.5rem;
`

interface SliderImgDestinationProps {
  src: string
  text?: string
  isBrowse?: boolean
  alt: string
  to?: string
  height?: string
  subtext?: string
}

export const SliderImgDestination: React.FC<SliderImgDestinationProps> = (props) => {
  const { src, text, isBrowse = true, alt, to = '#', height = 'inherit', subtext } = props
  const intl = useIntl()

  const capitalize = (subtext: string) =>
    subtext
      .toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
      .split('-')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join('-')

  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  const theLink = `${dataCanonical.site.siteMetadata.siteUrl}`

  return (
    <ImgMainDiv>
      <Image
        src={src}
        alt={alt}
        layout="FULL_WIDTH"
        className="slide-img"
        style={{ height: '370px', width: '100%', zIndex: 0 }}
      />
      <Details>
        {text && (
          <Box className="img-txt">
            <a href={theLink + to} style={{ color: 'inherit', textDecoration: 'none' }}>
              <span>
                {text.split(' ')[0].length < 4
                  ? text.split(' ')[0] + ' ' + text.split(' ')[1]
                  : text.split(' ')[0]}
              </span>{' '}
              <br />{' '}
              <span>
                {text.split(' ')[0].length < 4
                  ? text.split(' ').splice(2).join(' ')
                  : text.split(' ').splice(1).join(' ')}
              </span>
            </a>
          </Box>
        )}
      </Details>
      <Box className="browse-txt">
        {subtext?.split(' - ')[0] ? (
          <>
            <span>{capitalize(subtext?.split(' - ')[0])}</span> <br />
          </>
        ) : null}
        {subtext?.split(' - ')[1] ? (
          <>
            <span>{capitalize(subtext?.split(' - ')[1])}</span> <br />
          </>
        ) : null}
        {subtext?.split(' - ')[2] ? (
          <>
            <span>{capitalize(subtext?.split(' - ')[2])}</span> <br />
          </>
        ) : null}
        {subtext?.split(' - ')[3] ? (
          <>
            <span>{capitalize(subtext?.split(' - ')[3])}</span> <br />
          </>
        ) : null}
        {subtext?.split(' - ')[4] ? (
          <>
            <span>{capitalize(subtext?.split(' - ')[4])}</span> <br />
          </>
        ) : null}
        {subtext?.split(' - ')[5] ? (
          <>
            <span>{capitalize(subtext?.split(' - ')[5])}</span> <br />
          </>
        ) : null}
        {subtext?.split(' - ')[6] ? (
          <>
            <span>{capitalize(subtext?.split(' - ')[6])}</span> <br />
          </>
        ) : null}
        {subtext?.split(' - ')[7] ? (
          <>
            <span>{capitalize(subtext?.split(' - ')[7])}</span> <br />
          </>
        ) : null}
        {subtext?.split(' - ')[8] ? (
          <>
            <span>{capitalize(subtext?.split(' - ')[8])}</span> <br />
          </>
        ) : null}
      </Box>
    </ImgMainDiv>
  )
}
