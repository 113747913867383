import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { useIntl } from 'gatsby-plugin-intl'
import { Image } from '../Images'
import { CustomButton } from '../CustomButton'
import { navigate } from 'gatsby'
import { Typography } from '@material-ui/core'
import { Link as GatsbyLink } from 'gatsby'

const useStyles = (isTitleHanging = false) =>
  makeStyles((theme) => ({
    root: {
      width: '100%',
      padding: '5em 2em',
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.between('xs', 'sm')]: {
        padding: '4em 2em',
      },
    },
    innerDiv: {
      flexBasis: '80%',
      justifyContent: 'space-between',
      [theme.breakpoints.between('xs', 'sm')]: {
        flexBasis: 'unset',
      },
    },
    title: {
      fontFamily: `'Cormorant Garamond', serif`,
      textTransform: 'uppercase',
      lineHeight: '1',
      letterSpacing: '0em',
      fontSize: theme.typography.pxToRem(55),
      fontWeight: 400,
      [theme.breakpoints.between('md', 'md')]: {
        fontSize: theme.typography.pxToRem(40),
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: theme.typography.pxToRem(35),
      },
    },
    detail: {
      fontFamily: `'Cormorant Garamond', serif`,
      fontSize: theme.typography.pxToRem(30),
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.between('md', 'md')]: {
        fontSize: theme.typography.pxToRem(15),
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: theme.typography.pxToRem(13),
      },
    },
    picture: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.between('xs', 'sm')]: {
        marginTop: theme.spacing(3),
      },
    },
    btnTxt: {
      fontFamily: `'Gotham Book', serif`,
      fontSize: theme.typography.pxToRem(13.8),
      [theme.breakpoints.between('xs', 'md')]: {
        fontSize: theme.typography.pxToRem(10),
      },
      color: 'inherit',
    },
    btnLink: {
      textDecoration: 'none',
      textTransform: 'initial',
      color: 'inherit',
      '&:hover': {
        textDecoration: 'none',
        borderBottomColor: theme.palette.text.primary,
      },
    },
    btnDiv: {
      marginTop: theme.spacing(4),
      [theme.breakpoints.between('md', 'md')]: {
        marginTop: theme.spacing(3),
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        marginTop: theme.spacing(0),
      },
    },
    copyright: {
      fontSize: '0.8rem', 
      paddingBottom: '1px', 
      fontWeight: 400,
      [theme.breakpoints.between('md', 'md')]: {
        fontSize: '0.7rem', 
        fontWeight: 300,
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        fontSize: '0.5rem', 
        fontWeight: 300,
      },
    }
  }))

interface FriendsOfHouseProps {
  isTitleHanging?: boolean
  showBtn?: boolean
}

export const FriendsOfHouse: React.FC<FriendsOfHouseProps> = (props) => {
  const intl = useIntl()
  const { isTitleHanging = false, showBtn } = props
  const classes = useStyles(isTitleHanging)()
  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item md={9} sm={10}>
        <Grid item sm={12} className={classes.title}>
          <Grid item sm={12}>
            {intl.formatMessage({ id: 'friend.title_1' })}
          </Grid>
          <Grid item sm={12}>
            {intl.formatMessage({ id: 'friend.title_2' })}
          </Grid>
        </Grid>
        <Grid item className={classes.picture}>
          <Image src="vanessa_paradis_W9_baptiste_descroix_crop.jpeg" alt="friends-copyright Baptiste DESCROIX" layout="CONSTRAINED" />
        </Grid>
        <Grid item className={classes.copyright}>
          <span>
            {'© Baptiste Descroix / W9'}
          </span>
        </Grid>
        <Grid item className={classes.detail}>
          {intl.formatMessage({ id: 'friend.detail' })}
        </Grid>
        {showBtn && (
          <Grid item xs={12} className={classes.btnDiv}>
            <CustomButton>
              <GatsbyLink to={`/${intl.locale}/agency#friend`} color="inherit" className={classes.btnLink}>
                <Typography className={classes.btnTxt}>
                  {intl.formatMessage({ id: 'friend.btnText' })}
                </Typography>
              </GatsbyLink>
            </CustomButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default FriendsOfHouse
