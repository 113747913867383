import React from 'react'

const VideoAgency = ({ mp4, poster }: any) => {
  return (
    <video
      onMouseEnter={(e: any) => {
        if (e.target.currentTime == 0) e.target.play()
      }}
      onClick={(e: any) => {
        if (e.target.paused) {
          e.target.play()
        } else {
          e.target.pause()
        }
      }}
      onTouchStart={(e: any) => {
        if (e.target.paused) {
          e.target.play()
        } else {
          e.target.pause()
        }
      }}
      autoPlay={false}
      loop
      muted
      playsInline
      preload={'auto'}
      poster={poster}
      style={{ width: '100%', objectFit: 'fill', aspectRatio: '1.781818', cursor: 'pointer' }}
    >
      <source src={mp4} type="video/mp4" />
    </video>
  )
}

export default VideoAgency
