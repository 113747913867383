import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Link as GatsbyLink } from 'gatsby'

import LogoSvg from '../../icons/icon.svg'
import { KretzIconCircle } from '../../icons/KretzIconCircle'
import { CustomButton } from '../CustomButton'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'

import { useIntl } from 'gatsby-plugin-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `'Cormorant Garamond', serif`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    [theme.breakpoints.between('md', 'md')]: {
      fontFamily: `'Cormorant Garamond', serif`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      position: 'relative',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontFamily: `'Cormorant Garamond', serif`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      position: 'relative',
      padding: '0 2em',
      paddingBottom: '4em',
    },
  },
  circle: {
    height: '192px',
    width: '192px',
    position: 'absolute',
    top: '-48px',
    right: '-48px',
    [theme.breakpoints.between('md', 'md')]: {
      height: '144px',
      width: '144px',
      position: 'absolute',
      top: '-36px',
      right: '-36px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      position: 'unset',
      height: '120px',
      width: '120px',
      margin: '.8em 0',
      top: 'unset',
      right: 'unset',
    },
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    textTransform: 'uppercase',
    lineHeight: '1',
    letterSpacing: '0em',
    fontSize: theme.typography.pxToRem(55),
    fontWeight: 400,
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(35),
    },
  },
  logoSVG: {
    margin: '40px 0',
    width: '135px',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '26px 0',
      width: '120px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '25px 0',
      width: '120px',
    },
  },
  description: {
    lineHeight: '1.675',
    fontSize: theme.typography.pxToRem(25),
    marginTop: theme.spacing(6),
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(4),
      lineHeight: '1.675',
      fontFamily: `'Gotham Book', serif`,
      fontSize: theme.typography.pxToRem(15),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(4),
      lineHeight: '1.675',
      fontSize: theme.typography.pxToRem(13),
      fontFamily: `'Gotham Book', serif`,
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnLinkPers: {
    textDecoration: 'none',
    textTransform: 'initial',
    width:'200px',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },

  btnDiv: {
    marginTop: theme.spacing(6),
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(4),
    },
  },
}))

interface IntroProps {
  title: string
  description: string
  showCircle?: boolean
  showBtn?: boolean
}

export const Intro: React.FC<IntroProps> = (props) => {
  const intl = useIntl()
  const { title, description, showCircle = true, showBtn = true } = props
  const classes = useStyles()
  const scrollToEstimate = () => {
    const estimateSection = document.getElementById('estimate-section')

    if (estimateSection) {
      estimateSection.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <Grid container justifyContent="center" className={classes.root}>
        {showCircle && (
          <>
            <Hidden smDown>
              <KretzIconCircle className={classes.circle} />
            </Hidden>
            <Hidden mdUp>
              <KretzIconCircle className={classes.circle} />
            </Hidden>
          </>
        )}
        <img src={LogoSvg} alt="Kretz" className={classes.logoSVG} loading="lazy" />
        <Box display="flex" flexDirection="column" className={classes.title}>
          <Box>
            {title.split('\n').map((s: string, index: number) => (
              <div key={index}>{s}</div>
            ))}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" className={classes.description}>
          {description.split('\n').map((s) => (
            <div key={s}>{s}</div>
          ))}
        </Box>
        {showBtn && (
          <Grid className={classes.btnDiv}>
            <CustomButton width='200px'>
              <GatsbyLink to={`/${intl.locale}/agency`} color="inherit" className={classes.btnLink}>
                <Typography className={classes.btnTxt}>
                  {intl.formatMessage({ id: 'discoverTheAgency' })}
                </Typography>
              </GatsbyLink>
            </CustomButton>
            <Grid style={{ marginTop: '20px' }}>
              <CustomButton
                width='200px'     
                className={classes.btnLinkPers}
                onClick={scrollToEstimate}
              >
                <Typography className={classes.btnTxt}>
                  {intl.formatMessage({ id: 'estimate.estimateMyProperty' })}
                </Typography>
              </CustomButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default Intro
