import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import { CustomButton } from '../CustomButton'
import { Image } from '../Images'
import { Link as GatsbyLink } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'md')]: {
      marginBottom: theme.spacing(8),
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      padding: '4em 2em 0em 2em',
    },
  },
  inner: {
    flexBasis: '80%',
    justifyContent: 'space-between',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: 'unset',
    },
  },
  img: {
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      height: '100%',
    },
  },
  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '1',
    letterSpacing: '0em',
    fontSize: theme.typography.pxToRem(55),
    fontWeight: 400,
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(35),
    },
  },
  detail: {
    lineHeight: '1.875',
    fontSize: '1rem',
    marginTop: theme.spacing(6),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(4),
      fontSize: theme.typography.pxToRem(13),
      fontFamily: `'Gotham Book', serif`,
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btndiv: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(0),
    },
  },
}))

export const Network = () => {
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Box className={classes.root}>
      <Grid container className={classes.inner}>
        <Grid item md={6} sm={12}>
          <Hidden mdUp>
            <Grid container justifyContent={'center'} xs={12}>
              <Grid item xs={12}>
                <Image
                  src="HP_Network_bbny-paris.jpg"
                  className={classes.img}
                  alt="municipales-paris"
                  layout="FULL_WIDTH"
                />
              </Grid>
            </Grid>
          </Hidden>
          <Grid item sm={12} md={10} className={classes.title}>
            {intl
              .formatMessage({ id: 'network.title' })
              .split('\n')
              .map((str) => (
                <div>{str}</div>
              ))}
          </Grid>
          <Grid item sm={12} md={10} className={classes.detail}>
            {intl
              .formatMessage({ id: 'network.detail' })
              .split('\n')
              .map((s, index) => (
                <div key={index} style={{ marginBottom: 0 }}>
                  {s}
                </div>
              ))}
          </Grid>
          <Grid item sm={12} md={6} className={classes.btndiv}>
            <CustomButton>
              <GatsbyLink to={`/${intl.locale}/agency`} color="inherit" className={classes.btnLink}>
                <Typography className={classes.btnTxt}>
                  {intl.formatMessage({ id: 'network.btnText' })}
                </Typography>
              </GatsbyLink>
            </CustomButton>
          </Grid>
        </Grid>
        <Grid container sm={12} md={6}>
          <Hidden mdDown>
            <Grid container md={12} justifyContent='flex-end'>
              <Grid item md={9} style={{ marginTop: '5%', zIndex: 2 }}>
                <Image
                  src="municipales-paris-2020.png"
                  className={classes.img}
                  alt="municipales-paris"
                  layout="CONSTRAINED"
                  imgStyle={{ border: 'solid 1rem #fff' }}
                  style={{ height: '75%', width: '100%', zIndex: 0 }}
                />
              </Grid>
            </Grid>
            <Grid container md={12} justifyContent='flex-start' style={{ height: 'auto', marginTop: '-25%', zIndex: 1 }}>
              <Grid item md={9}>
                <Image
                  src="HP_Network_bbny.jpg"
                  className={classes.img}
                  alt="Broooklyn bridge"
                  layout="CONSTRAINED"
                  style={{ height: '85%', width: '100%', zIndex: 0 }}
                />
              </Grid>
            </Grid>
          </Hidden>
          <Hidden smDown lgUp>
            <Grid item xs={12}>
              <Image
                src="municipales-paris-2020.png"
                className={classes.img}
                alt="municipales-paris"
                layout="CONSTRAINED"
              //imgStyle={{ border: 'solid 1rem #fff' }}
              />
              <Image
                src="HP_Network_bbny.jpg"
                className={classes.img}
                alt="Brooklyn bridge"
                layout="CONSTRAINED"
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Network
