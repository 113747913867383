import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Image } from '../Images'
import { useIntl } from 'gatsby-plugin-intl'
import { CustomButton } from '../CustomButton'
import VideoAgency from './VideosAgency'
import { Link as GatsbyLink } from 'gatsby'
import { Typography } from '@material-ui/core'
import videoRub1Mp4 from '../../images/rubriques-1.mp4'
import videoRub2Mp4 from '../../images/rubriques-2.mp4'
import videoRub3Mp4 from '../../images/rubriques-3.mp4'
import posterRub1Jpg from '../../images/poster_rub1.jpg'
import posterRub2Jpg from '../../images/poster_rub2.jpg'
import posterRub3Jpg from '../../images/poster_rub3.jpg'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `'Cormorant Garamond', serif`,
    backgroundColor: theme.palette.info.main,
    padding: '5em 2em',
    textAlign: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 2em',
    },
  },
  agencyDetailsTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    textTransform: 'uppercase',
    lineHeight: '1',
    letterSpacing: '0em',
    fontSize: theme.typography.pxToRem(55),
    fontWeight: 400,
    marginTop: theme.spacing(-2),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      fontSize: theme.typography.pxToRem(35),
    },
  },
  sub_title: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(34),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(25),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(20),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  detail: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(30),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(15),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(13),
    },
  },
  images: {
    paddingRight: '0.4rem',
    paddingLeft: '0.4rem',
  },
  topImg: {
    width: '18em',
    [theme.breakpoints.between('md', 'md')]: {
      width: '14em',
      marginTop: '-4rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '10em',
      marginTop: '-4rem',
    },
  },
  episode: {
    paddingBottom: '4rem',
  },
  episodeTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(34),
    fontWeight: 'bold',
    padding: '1.3rem 0',
    textAlign: 'left',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(25),
      padding: '.8rem 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(20),
      padding: '.3rem 0',
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnDiv: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
    },
  },
}))

interface AgencyDetailsProps {
  showSildeShow?: boolean
  showSubtitle?: boolean
  showEpisode?: boolean
}

export const AgencyDetails: React.FC<AgencyDetailsProps> = (props) => {
  const { showSildeShow = true, showSubtitle = true, showEpisode = false } = props
  const classes = useStyles()
  const intl = useIntl()

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item justifyContent="center" sm={8}>
        <Image
          src="netflix_group.png"
          alt="Netflix"
          layout="CONSTRAINED"
          className={classes.topImg}
        />
        <Grid item sm={12} className={classes.agencyDetailsTitle}>
          {intl.formatMessage({ id: 'agency.title' })}
        </Grid>
        {showSubtitle && (
          <Grid item sm={12} className={classes.sub_title}>
            {intl.formatMessage({ id: 'agency.sub_title' })}
          </Grid>
        )}
        {showSildeShow && (
          <Grid container direction="row" justifyContent="space-between">
            <Grid item md={4} className={classes.images}>
              <VideoAgency mp4={videoRub1Mp4} poster={posterRub1Jpg} />
            </Grid>
            <Grid item md={4} className={classes.images}>
              <VideoAgency mp4={videoRub2Mp4} poster={posterRub2Jpg} />
            </Grid>
            <Grid item md={4} className={classes.images}>
              <VideoAgency mp4={videoRub3Mp4} poster={posterRub3Jpg} />
            </Grid>
          </Grid>
        )}
        <Grid container justifyContent="center">
          <Grid item className={classes.detail}>
            {intl.formatMessage({ id: 'agency.details' })}
          </Grid>
        </Grid>
        {showEpisode && (
          <>
            <Grid item xs={12} className={classes.episodeTitle}>
              <Box>{intl.formatMessage({ id: 'episode' })}</Box>
            </Grid>
            <Grid item xs={12} className={classes.episode}>
              <Image src="a4.png" alt="buy_banner" layout="FULL_WIDTH" />
            </Grid>
          </>
        )}
        <Grid item xs={12} className={classes.btnDiv}>
          <CustomButton>
            <GatsbyLink target={'_blank'} to={`https://www.netflix.com/fr/title/81417684`} color="inherit" className={classes.btnLink}>
              <Typography className={classes.btnTxt}>
                {intl.formatMessage({ id: 'agency.btnText' })}
              </Typography>
            </GatsbyLink>
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AgencyDetails
